import React, { useState } from 'react';
import './Product.css';
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';
import { CiStar } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";
import { shopItemTwo,
        shopItemThree,
        shopItemOne,
        productImgOne,
        productImgTwo,
        productImgThree } from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';


const products = [
    {
        id: 1,
        name: 'Assorted Small Succulents',
        price: 10,
        image: shopItemOne,
        reviews: '5 reviews',
        ratings: 0 
    },
    {
        id: 2,
        name: 'Small Aloe Vera',
        price: 10,
        image: shopItemTwo,
        reviews: '8 reviews',
        ratings: 0
    },
    {
        id: 3,
        name: 'Medium Bonsai',
        price: 25,
        image: shopItemThree,
        reviews: '15 reviews',
        ratings: 0
    },
];

const Products = () => {

  const [mainImage, setMainImage] = useState({
    src: productImgTwo,
    alt: 'Assorted Small Succulents'
  });

  const changeImage = (src, alt) => {
    setMainImage({ src, alt });
  };
  

  return (
    <>
      <Helmet>
        <title>SWEET BAE | Explore Our Succulent Collection</title>
        <meta name="description" content="Explore our handpicked assortment of small, easy-to-care-for succulents that add a touch of green to any space." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="wrapper">
        <div className="product-sidebar">
            <div class="product-filter-sidebar">
                <div className="product-details">
                    <h1>Assorted Small Succulents</h1>
                    <h2>$10</h2>
                    <p>
                    Receive a curated succulent from one of our best seller collection,
                    easy to care for and always in season!
                    </p>
                    <div className="side-ratings">
                        <CiStar className="ratings-star"/>
                        <CiStar className="ratings-star"/>
                        <CiStar className="ratings-star"/>
                        <CiStar className="ratings-star"/>
                        <CiStar className="ratings-star"/>
                        # reviews
                    </div>
                    <div className="add-to-cart">
                        <button><Link to="/" reloadDocument>ADD TO CART</Link></button>
                    </div>
                    <div className="buy-now">
                        <button><Link to="/" reloadDocument>BUY NOW</Link></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="main">
          <div>
            <div className="product-display">
              <div className="product-image">
                <img id="main-image" src={mainImage.src} alt={mainImage.alt} />
                <div className="image-subImage">
                    <img 
                    src={productImgOne}
                    alt="subImage1" 
                    onClick={() => changeImage(productImgOne, 'subImage1')}
                    />
                    <img 
                    src={productImgTwo}
                    alt="subImage2" 
                    onClick={() => changeImage(productImgTwo, 'subImage2')}
                    />
                    <img 
                    src={productImgThree} 
                    alt="subImage3" 
                    onClick={() => changeImage(productImgThree, 'subImage3')}
                    />
                </div>
              </div>       
            </div>
            <div>
                <div className="product-header">
                    <h2>Similar Product</h2>
                </div>
                <div className="product-shop-grid">
                    {products.map(plant => (
                    <div className="product-plant-item" key={plant.id}>
                        <img src={plant.image} alt={plant.name} />
                            <div className="description">
                                <div className="info">
                                    <span className="name"><h4>{plant.name}</h4></span>
                                </div>
                                <span className="price">${plant.price}</span>
                            </div>
                        <div className="meta">
                            <span className="ratings">
                                <CiStar className="ratings-star"/>
                                <CiStar className="ratings-star"/>
                                <CiStar className="ratings-star"/>
                                <CiStar className="ratings-star"/>
                                <CiStar className="ratings-star"/>
                            </span>
                        <span className="reviews">{plant.reviews}</span>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default pageTransition(Products);