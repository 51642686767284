import React from 'react';
import { Link } from 'react-router-dom';
import { FaSquareFacebook, FaInstagram, FaWhatsapp, FaCcVisa, FaCcMastercard, FaCcAmex, FaPaypal, FaCcJcb, FaCcDiscover, FaCcDinersClub } from "react-icons/fa6";
import './Footer.css';

const Footer = () => {
    return(
        <div>
          <div className='footer'>
            <div className="footer-left">
              <div className="footer-links">
                <ul className="footer-nav">
                  <li className="footer-upper"><Link to="/blog" reloadDocument>Blog</Link></li>
                  <li className="footer-upper"><Link to="/all-services" reloadDocument>Services</Link></li>
                  <li className="footer-upper"><Link to="/plant-directory" reloadDocument>Plant Directory</Link></li>
                  <li className="footer-upper"><Link to="/all-case-studies" reloadDocument>Case Studies</Link></li>
                </ul>
                <ul className="footer-nav">
                  <li className="footer-second"><Link to="/about" reloadDocument>About</Link></li>
                  <li className="footer-second"><Link to="/contact" reloadDocument>Contact</Link></li>
                  <li className="footer-second"><Link to="/legal" reloadDocument>Legal</Link></li>
                  <li className="footer-second"><Link to="/privacy" reloadDocument>Privacy</Link></li>
                </ul>
              </div>
              <div className="footer-socials">
                <Link to="/" reloadDocument><FaSquareFacebook /></Link>
                <Link to="/" reloadDocument><FaInstagram /></Link>
                <Link to="/" reloadDocument><FaWhatsapp /></Link>
              </div>
              <form className="search-form">
                <input type="text" aria-label="Subscribe for updates" />
                <button onclick="location.href='index.html'">Subscribe</button>
              </form>
            </div>
            <div className="footer-right">
              <div className="logo-copyright">
                <Link to="/" reloadDocument>
                  <div className="company-name">Sweet Bae</div>
                </Link>
                <p>&copy; 2021 Sweet Bae. All Rights Reserved.</p>
              </div>
              <div className="payment-methods">
                <Link to="/" reloadDocument><FaCcVisa /></Link>
                <Link to="/" reloadDocument><FaCcMastercard /></Link>
                <Link to="/" reloadDocument><FaCcAmex /></Link>
                <Link to="/" reloadDocument><FaCcJcb /></Link>
                <Link to="/" reloadDocument><FaCcDiscover /></Link>
                <Link to="/" reloadDocument><FaCcDinersClub /></Link>
                <Link to="/" reloadDocument><FaPaypal /></Link>
              </div>
            </div>
          </div>
      </div>
    );
}

export default Footer;