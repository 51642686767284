import slideImgOne from './images/slideImg/slideImg1.jpg';
import slideImgTwo from './images/slideImg/slideImg2.jpg';
import slideImgThree from './images/slideImg/slideImg3.jpg';
import shopItemOne from './images/shop/succulents.jpg';
import shopItemTwo from './images/shop/aloeVera.jpeg';
import shopItemThree from './images/shop/bonsai.jpg';
import shopItemFour from './images/shop/orchid.jpeg';
import shopItemFive from './images/shop/whitePeace.jpeg';
import shopItemSix from './images/shop/monstera.jpg';
import shopItemSeven from './images/shop/snakePlant.jpeg';
import shopItemEight from './images/shop/cactus.jpeg';
import shopItemNine from './images/shop/officeBundle.jpeg';
import aboutImgOne from './images/about/about-slide1.jpg';
import aboutImgTwo from './images/about/about-slide2.jpg';
import aboutImgThree from './images/about/about-slide3.jpg';
import navHoverImg from './images/home/nav-hover-plant.jpg';
import shopItemsImgOne from './images/home/plant3.jpg';
import shopItemsImgTwo from './images/home/pot.jpeg';
import shopItemsImgThree from './images/home/eventgifts.jpeg';
import shopItemsImgFour from './images/home/corpgift.jpg';
import blogImgOne from './images/home/promo-plant1.jpg';
import blogImgTwo from './images/home/promo-plant2.jpg';
import blogImgThree from './images/home/promo-plant3.jpg';
import shopImgOne from './images/home/plant5.jpg';
import shopImgTwo from './images/home/plant6.jpeg';
import serviceImgOne from './images/services/plantInstallation.jpg';
import serviceImgTwo from './images/services/plantMaint.jpg';
import serviceImgThree from './images/services/plantDes.jpg';
import serviceImgFour from './images/services/plantClass.png';
import serviceImgFive from './images/services/seasonalPlanting.jpg';
import serviceImgSix from './images/services/plantHealth.jpg';
import plantInstallSlideOne from './images/services/plantInstallSlideOne.jpg';
import plantInstallSlideTwo from './images/services/plantInstallSlideTwo.jpg'
import plantMaintSlideOne from './images/services/plantMaintSlideOne.jpg';
import plantMaintSlideTwo from './images/services/plantMaintSlideTwo.jpg';
import plantClassSlideOne from './images/services/plantClassSlideOne.jpg';
import plantClassSlideTwo from './images/services/plantClassSlideTwo.jpg';
import plantHealthSlideOne from './images/services/plantHealthSlideOne.jpg';
import plantHealthSlideTwo from './images/services/plantHealthSlideTwo.jpg';
import plantDesignSlideOne from './images/services/plantDesignSlideOne.png';
import plantDesignSlideTwo from './images/services/plantDesignSlideTwo.jpg';
import seasonalPlaintingSlideOne from './images/services/seasonalPlaintingSlideOne.jpg';
import seasonalPlaintingSlideTwo from './images/services/seasonalPlaintingSlideTwo.jpg';
import allCaseStudiesImgOne from './images/caseStudies/caseStudyImgOne.jpg';
import allCaseStudiesImgTwo from './images/caseStudies/caseStudyImgTwo.jpg';
import allCaseStudiesImgThree from './images/caseStudies/caseStudyImgThree.jpeg';
import allCaseStudiesImgFour from './images/caseStudies/caseStudyImgFour.jpg';
import allCaseStudiesImgFive from './images/caseStudies/caseStudyImgFive.jpeg';
import allCaseStudiesImgSix from './images/caseStudies/caseStudyImgSix.png';
import plantHealthOne from './images/caseStudies/plantHealthOne.jpeg';
import plantHealthTwo from './images/caseStudies/plantHealthTwo.jpg';
import plantHealthThree from './images/caseStudies/plantHealthThree.jpg';
import SeasonalPlantingOne from './images/caseStudies/seasonPlantOne.jpg';
import SeasonalPlantingTwo from './images/caseStudies/seasonPlantTwo.jpg';
import SeasonalPlantingThree from './images/caseStudies/seasonPlantThree.jpeg';
import classPlantOne from './images/caseStudies/classPlantOne.jpg';
import classPlantTwo from './images/caseStudies/classPlantTwo.jpg';
import classPlantThree from './images/caseStudies/classPlantThree.jpg';
import plantDesignOne from './images/caseStudies/plantDesignOne.jpg';
import plantDesignTwo from './images/caseStudies/plantDesignTwo.jpg';
import plantDesignThree from './images/caseStudies/plantDesignThree.jpg';
import officePlantOne from './images/caseStudies/officePlantOne.jpg';
import officePlantTwo from './images/caseStudies/officePlantTwo.jpg';
import officePlantThree from './images/caseStudies/officePlantThree.jpg';
import resortPlantOne from './images/caseStudies/resortPlantOne.jpg';
import resortPlantTwo from './images/caseStudies/resortPlantTwo.jpg';
import resortPlantThree from './images/caseStudies/resortPlantThree.jpg';
import productImgOne from './images/product/product-plants1.jpg';
import productImgTwo from './images/product/product-plants2.jpg';
import productImgThree from './images/product/product-plants3.jpg';
import individualPlant from './images/shop/individualPlant.jpg';
import blogHeroImage from './images/blog/blogHeroImage.jpg';
import avatarOne from './images/testimonial/iconOne.jpg';
import avatarTwo from './images/testimonial/iconTwo.jpg';
import avatarThree from './images/testimonial/iconThree.jpg';
import avatarFour from './images/testimonial/iconFour.jpg';

export{
    slideImgOne,
    slideImgTwo,
    slideImgThree,
    navHoverImg,
    shopItemsImgOne,
    shopItemsImgTwo,
    shopItemsImgThree,
    shopItemsImgFour,
    blogImgOne,
    blogImgTwo,
    blogImgThree,
    shopItemOne,
    shopItemTwo,
    shopItemThree,
    shopItemFour,
    shopItemFive,
    shopItemSix,
    shopItemSeven,
    shopItemEight,
    shopItemNine,
    aboutImgOne,
    aboutImgTwo,
    aboutImgThree,
    shopImgOne,
    shopImgTwo,
    serviceImgOne,
    serviceImgTwo,
    serviceImgThree,
    serviceImgFour,
    serviceImgFive,
    serviceImgSix,
    plantInstallSlideOne,
    plantInstallSlideTwo,
    plantMaintSlideOne,
    plantMaintSlideTwo,
    plantClassSlideOne,
    plantClassSlideTwo,
    plantHealthSlideOne,
    plantHealthSlideTwo,
    plantDesignSlideOne,
    plantDesignSlideTwo,
    seasonalPlaintingSlideOne,
    seasonalPlaintingSlideTwo,
    allCaseStudiesImgOne,
    allCaseStudiesImgTwo,
    allCaseStudiesImgThree,
    allCaseStudiesImgFour,
    allCaseStudiesImgFive,
    allCaseStudiesImgSix,
    plantHealthOne,
    plantHealthTwo,
    plantHealthThree,
    SeasonalPlantingOne,
    SeasonalPlantingTwo,
    SeasonalPlantingThree,
    classPlantOne,
    classPlantTwo,
    classPlantThree,
    plantDesignOne,
    plantDesignTwo,
    plantDesignThree,
    officePlantOne,
    officePlantTwo,
    officePlantThree,
    resortPlantOne,
    resortPlantTwo,
    resortPlantThree,
    productImgOne,
    productImgTwo,
    productImgThree,
    individualPlant,
    blogHeroImage,
    avatarOne,
    avatarTwo,
    avatarThree,
    avatarFour
}