import React from 'react';
import './Privacy.css';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const Privacy = () => {
  return (
    <>
      <Helmet>
          <title>SWEET BAE | Privacy Policy</title>
          <meta name="description" content="Learn how SWEET BAE protects and utilizes your personal information. Read our detailed privacy policy to understand your rights and our responsibilities." />
      </Helmet>
      <hr />
      <div class="privacy-content">
        <h1>Privacy Policy</h1>
        <h2>Privacy Statement</h2>
        <span>----</span>
        <h2>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h2>
        <p>
          When you purchase something from our store, as part of the buying and
          selling process, we collect the personal information you give us such
          as your name, address and email address.
          <br />
          When you browse our store, we also automatically receive your
          computer’s internet protocol (IP) address in order to provide us with
          information that helps us learn about your browser and operating
          system.
          <br />
          Email marketing (if applicable): With your permission, we may send you
          emails about our store, new products and other updates.
        </p>

        <h2>SECTION 2 - CONSENT</h2>
        <p>
          How do you get my consent? When you provide us with personal
          information to complete a transaction, verify your credit card, place
          an order, arrange for a delivery or return a purchase, we imply that
          you consent to our collecting it and using it for that specific reason
          only.
          <br />
          How do I withdraw my consent? If after you opt-in, you change your
          mind, you may withdraw your consent for us to contact you, for the
          continued collection, use or disclosure of your information, at
          anytime, by contacting us at
          <a href="mailto:eden@e-dimensionz.com">eden@e-dimensionz.com</a>
        </p>

        <h2>SECTION 3 - DISCLOSURE</h2>
        <p>
          We may disclose your personal information if we are required by law to
          do so or if you violate our Terms of Service.
        </p>

        <h2>SECTION 4 - SHOPIFY</h2>
        <p>
          Our store is hosted on Shopify Inc. They provide us with the online
          e-commerce platform that allows us to sell our products and services
          to you.
          <br />
          Your data is stored through Shopify’s data storage, databases and the
          general Shopify application. They store your data on a secure server
          behind a firewall.
        </p>

        <h2>SECTION 5 - THIRD-PARTY SERVICES</h2>
        <p>
          In general, the third-party providers used by us will only collect,
          use and disclose your information to the extent necessary to allow
          them to perform the services they provide to us.
        </p>

        <h2>SECTION 6 - SECURITY</h2>
        <p>
          To protect your personal information, we take reasonable precautions
          and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed.
        </p>

        <h2>SECTION 7 - COOKIES</h2>
        <p>
          Here is a list of cookies that we use. We’ve listed them here so you
          that you can choose if you want to opt-out of cookies or not.
          <br />
          _session_id, unique token, sessional, Allows Shopify to store
          information about your session (referrer, landing page, etc).
          <br />
          _shopify_visit, no data held, Persistent for 30 minutes from the last
          visit, Used by our website provider’s internal stats tracker to record
          the number of visits
          <br />
          _shopify_uniq, no data held, expires midnight (relative to the
          visitor) of the next day, Counts the number of visits to a store by a
          single customer.
          <br />
          cart, unique token, persistent for 2 weeks, Stores information about
          the contents of your cart.
          <br />
          _secure_session_id, unique token, sessional
          <br />
          storefront_digest, unique token, indefinite If the shop has a
          password, this is used to determine if the current visitor has access.
        </p>
        <h2>SECTION 8 - AGE OF CONSENT</h2>
        <p>
          By using this site, you represent that you are at least the age of
          majority in your state or province of residence, or that you are the
          age of majority in your state or province of residence and you have
          given us your consent to allow any of your minor dependents to use
          this site.
        </p>

        <h2>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h2>
        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website. If we make
          material changes to this policy, we will notify you here that it has
          been updated, so that you are aware of what information we collect,
          how we use it, and under what circumstances, if any, we use and/or
          disclose it.
        </p>
        <p>
          If our store is acquired or merged with another company, your
          information may be transferred to the new owners so that we may
          continue to sell products to you.
        </p>

        <h2>QUESTIONS AND CONTACT INFORMATION</h2>
        <p>
          If you would like to: access, correct, amend or delete any personal
          information we have about you, register a complaint, or simply want
          more information contact us at
          <a href="mailto:eden@e-dimensionz.com">eden@e-dimensionz.com</a>
        </p>
        <span>----</span>
      </div>
      <hr/>
    </>
  )
}

export default pageTransition(Privacy);