import React, { useState, useEffect, useRef } from 'react';
import { FaSquareFacebook, FaInstagram, FaWhatsapp, FaToggleOn } from "react-icons/fa6";
import { IoMdMenu, IoMdClose, IoIosArrowDown } from "react-icons/io";
import './Navbar.css';
import { navHoverImg } from '../../../assist/index';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [subDropdown, setSubDropdown] = useState(null);
  const dropdownRef = useRef(null);

  /*toggle hamburgermenu*/
  const toggleMenu = () => {
    const navLinks = document.querySelector('.nav-links');
    if (isActive) {
      navLinks.classList.remove('show');
      navLinks.classList.add('hide');
      navLinks.addEventListener('animationend', () => {
        setIsActive(false);
      }, { once: true });
    } else {
      setIsActive(true);
      navLinks.classList.remove('hide');
      navLinks.classList.add('show');
    }
  };

  const handleClick = (dropdownSetter) => {
    setActiveDropdown(prevDropdown => {
      if (prevDropdown === dropdownSetter) {
        return null;
      }
      return dropdownSetter;
    });
  };

  const handleSubClick = (subDropdownSetter) => {
    setSubDropdown(prevSubDropdown => {
      if (prevSubDropdown === subDropdownSetter) {
        return null;
      }
      return subDropdownSetter;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setSubDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*function for switch the light & dark mode*/
  useEffect(() => {
    const toggleTheme = () => {
      const body = document.body;
      const currentTheme = body.getAttribute('data-theme');
      const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
      body.setAttribute('data-theme', newTheme);
      localStorage.setItem('theme', newTheme);
    };

    window.toggleTheme = toggleTheme;

    const savedTheme = localStorage.getItem('theme') || 'dark';
    document.body.setAttribute('data-theme', savedTheme);
  }, []);

  return (
    <div className="header-content">
      <nav className="navbar" ref={dropdownRef}>
        <div className={`nav-lower ${isActive ? 'active' : ''}`}>
          <ul className="nav-links">
            <li><Link to="/plant-directory" reloadDocument>Plants Directory</Link></li>
            <li><Link to="/blog" reloadDocument>Blog</Link></li>
            <li className={`nav-dropdown ${activeDropdown === 'caseStudies' ? 'active' : ''}`} onClick={() => handleClick('caseStudies')}>
              <Link>Case Studies<IoIosArrowDown className={`nav-arrow-icon ${activeDropdown === 'caseStudies' ? 'rotate' : ''}`} /></Link>
              {activeDropdown === 'caseStudies' && (
                <div className="dropdown-content">
                  <ul className={`nav-dropdown ${subDropdown === 'allCaseStudies' ? 'active' : ''}`} onClick={(e) => {
                    e.stopPropagation();
                    handleSubClick('allCaseStudies');
                  }}>
                    <li>
                      <Link>Case Studies<IoIosArrowDown className={`nav-arrow-icon ${subDropdown === 'allCaseStudies' ? 'rotate' : ''}`} /></Link>
                      {subDropdown === 'allCaseStudies' && (
                        <ul className="sub-dropdown-content">
                          <li><Link to="/office-plant-install" reloadDocument>Office Plant Installation</Link></li>
                          <li><Link to="/resort-plant-maintenance" reloadDocument>Resort Plant Maintenance</Link></li>
                          <li><Link to="/custome-plant-design" reloadDocument>Custom Planter Design</Link></li>
                          <li><Link to="/education-plant-class" reloadDocument>Education Plant Classes</Link></li>
                          <li><Link to="/park-season-plant" reloadDocument>Seasonal Planting in park</Link></li>
                          <li><Link to="/resident-garden-plant-health" reloadDocument>Plant Health Diagnosis in Residence Garden</Link></li>
                        </ul>
                      )}
                    </li>
                  </ul>
                  <ul>
                    <li><Link to="/all-case-studies" reloadDocument>All Case Studies</Link></li>
                  </ul>
                </div>
              )}
            </li>
            <li className={`nav-dropdown ${activeDropdown === 'services' ? 'active' : ''}`} onClick={() => handleClick('services')}>
              <Link>Services<IoIosArrowDown className={`nav-arrow-icon ${activeDropdown === 'services' ? 'rotate' : ''}`} /></Link>
              {activeDropdown === 'services' && (
                <div className="submenu-container">
                  <div className="hover-image">
                    <img src={navHoverImg} alt="Special Offer" />
                  </div>
                  <ul className="hover-links">
                    <li><Link to="/plant-install" reloadDocument>Plant Installation</Link></li>
                    <li><Link to="/plant-maint" reloadDocument>Plant Maintenance</Link></li>
                    <li><Link to="/plant-design" reloadDocument>Custom Planter Design</Link></li>
                    <li><Link to="/plant-class" reloadDocument>Plant Classes</Link></li>
                    <li><Link to="/seasonal-planting" reloadDocument>Seasonal Planting</Link></li>
                    <li><Link to="/plant-health" reloadDocument>Plant Health Diagnosis</Link></li>
                    <li><Link to="/all-services" reloadDocument>All Services</Link></li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
          <div className="toggleMenu" onClick={toggleMenu}>
            {isActive ? <IoMdClose /> : <IoMdMenu />}
          </div>
        </div>
        <div className="logo">
          <a href="/">Sweet Bee</a>
          <button className="theme-toggle" onClick={() => window.toggleTheme()}>
            <FaToggleOn />
          </button>
        </div>
        <div>
          <div className={`nav-upper ${isActive ? 'active' : ''}`}>
            <div className="nav-socials">
              <Link to="/"><FaSquareFacebook /></Link>
              <Link to="/"><FaInstagram /></Link>
              <Link to="/"><FaWhatsapp /></Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
