import React from 'react';
import { Link } from 'react-router-dom';

const PickupInfo = () => {
  return (
    <section className="pickup-info">
        <div>
            <div className="pickup-info-overlay">
            <h2>View Pickup and Delivery Options</h2>
            <p>M - F: 9:00am - 6:00pm</p>
            <button>
                <Link to="/contact" reloadDocument>More Info</Link>
            </button>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.456324194087!2d-79.39477718450073!3d43.66872927912124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34b0fcaa03b7%3A0xcc2b1658740928a2!2sToronto!5e0!3m2!1sen!2sca!4v1622484070764!5m2!1sen!2sca"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              aria-label="Location map"
              title='Map'
            ></iframe>
        </div>
    </section>
  );
}

export default PickupInfo;
