import React, { useState }from 'react';
import { Helmet } from 'react-helmet';
import './IndividualBlog.css';
import pageTransition from '../../../variants/pageTransition';
import { blogHeroImage } from '../../../assist/index';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt } from "react-icons/fa";

const blogPosts = [
  {
    date: "September 10, 2024",
    title: "Indoor Air Quality Impacts Your Health",
  },
  {
    date: "September 01, 2024",
    title: "Easy Projects to Brighten Your Space",
  },
  {
    date: "August 15, 2024",
    title: "The Guide to Simple Home Improvements",
  },
  {
    date: "August 05, 2024",
    title: "Simple Tips for Improving Indoor Air",
  },
  {
    date: "July 25, 2024",
    title: "Transform Your Home with These Springtime DIY Projects",
  },
  {
    date: "July 10, 2024",
    title: "Quick Fixes for a Healthier, Fresher Living Space",
  },
  {
    date: "June 30, 2024",
    title: "From Air Quality to Indoor Comfort",
  },
  {
    date: "June 15, 2024",
    title: "Improve Your Indoor Environment",
  },
  {
    date: "May 20, 2024",
    title: "Top Ways to Boost Energy Efficiency in Your Home",
  },
  {
    date: "May 05, 2024",
    title: "Creative Ideas for a Greener Home Interior",
  },
  {
    date: "April 25, 2024",
    title: "How to Maintain a Healthy Living Environment",
  },
  {
    date: "April 10, 2024",
    title: "Eco-Friendly Home Renovation Projects",
  },
  {
    date: "March 30, 2024",
    title: "Smart Home Solutions for Better Air Quality",
  },
];

const IndividualBlog = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredBlogPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchTerm) || post.date.includes(searchTerm)
  );

  return (
    <div>
        <Helmet>
            <title>Sweet Bae | Individual Blog</title>
            <meta name="description" content="Discover a variety of plants and pots at the SWEET BAE shop. Whether you're looking for small succulents or large tropical plants, enjoy a 30% spring discount on all products!" />
        </Helmet>
        <div>
          <div className="container">
            <div className="content-wrapper">
              <div className="individual-blog-content">
                <article>
                  <div className="individual-blog-title">
                    <img src={blogHeroImage} alt="Blog Hero" className="blog-hero-image" />
                    <h1>Freshen Up Your Home: Simple Spring Projects with Indoor Greenery</h1>
                    <div className="blog-date">
                      <FaRegCalendarAlt/>
                      <span className="blog-time">September 12, 2024</span>
                    </div>
                    <div className="blog-time-update">
                      <span>Update: September 12, 2024</span>
                    </div>
                  </div>
                  <p>Spring is the perfect time to refresh your living space with vibrant and lush greenery. Simple spring projects, such as adding indoor plants, can transform your home, making it more inviting and aesthetically pleasing. In this blog post, we’ll explore how you can easily incorporate plants into your decor to create a serene and rejuvenating environment.</p>

                  <h2>The Beauty of Indoor Plants</h2>

                  <p>Indoor plants not only enhance the visual appeal of your home but also provide numerous benefits, including improved air quality and a sense of well-being. The plant featured in the image above is a perfect example of how a simple addition can bring life and color to any room. Its striking green leaves with variegated patterns add a touch of nature's artistry to your decor.</p>

                  <h2>Choosing the Right Plants</h2>

                  <p>When selecting plants for your indoor space, consider factors such as light availability, maintenance level, and the overall aesthetic you wish to achieve. Plants like the one in the image are ideal for bright, indirect light and require minimal upkeep, making them perfect for busy individuals.</p>

                  <h2>DIY Plant Projects</h2>

                  <h3>Potting and Placement: </h3>
                  <p>Choose stylish pots that complement your interior design. Place the plants in areas where they can thrive and be easily seen, such as on coffee tables, shelves, or window sills.</p>
                  
                  <h3>Decorative Arrangements:</h3>
                  
                  <p>Combine different plants to create a mini indoor garden. Mixing various leaf shapes, sizes, and colors can create a visually appealing arrangement.</p>
                  
                  <h3>Seasonal Themes:</h3>
                  
                  <p>Incorporate seasonal decor with your plants. For spring, consider adding elements like pastel-colored pots or decorative butterflies to enhance the seasonal vibe.</p>

                  <h2>Caring for Your Plants</h2> 

                  <h3>Watering:</h3>
                  <p>Ensure your plants are adequately watered. Over-watering can be as harmful as under-watering, so check the soil moisture regularly.</p>

                  <h3>Light:</h3>
                  <p>Position your plants according to their light requirements. Too much direct sunlight can scorch the leaves, while too little can hinder growth.</p>

                  <h3>Cleaning: </h3>
                  <p>Keep the leaves dust-free by gently wiping them with a damp cloth. This helps the plant photosynthesize more effectively.</p>

                  <p>Adding plants to your home is a simple yet impactful way to celebrate spring. Whether you’re an experienced gardener or a novice, there’s a plant for everyone. Start your spring project today and enjoy the beauty and benefits that indoor greenery brings.</p>
                </article>
              </div>
              <div className="individual-blog-sidebar">
                {/* Add search function */}
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <h2>Latest Articles </h2>
                <div className="individual-blog-post">
                  {filteredBlogPosts.map((post, index) => (
                    <div key={index} className="individual-blog-list-item">
                      <Link to={post.path} reloadDocument>
                      <div className="blog-post-header">
                        <h3>{post.title}</h3>
                      </div>
                        <p>{post.date}</p>
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="individual-blog-contact-us">
                  <h3>More information? Get in touch today!</h3>
                  <Link to="/contact" reloadDocument><button>Get in touch</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
    </div>
  )
}

export default pageTransition(IndividualBlog);