import React from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const Contact = () => {
  return (
    <div>
        <Helmet>
            <title>SWEET BAE | Contact Us</title>
            <meta name="description" content="Reach out to CICELY for inquiries about our sustainable and bespoke plant services. Connect with our expert team today to enhance your spaces." />
        </Helmet>
        <p class="discount">30% off spring sale</p>
        <div class="contact-hero">
            <div class="contact-hero-content">
                <h1>
                    Contact Us
                </h1>
            </div>
        </div>
        <div class="container">
            <div class="contact-container">
                <h2>SEND US AN EMAIL</h2>
                <form>
                    <div class="input-row">
                        <div class="input-group">
                            <label for="name">Name</label>
                            <input type="text" id="name" name="name" required/>
                        </div>
                        <div class="input-group">
                            <label for="email">Email</label>
                            <input type="email" id="email" name="email" required/>
                        </div>
                    </div>
                    <div class="input-group">
                        <label for="message">Message</label>
                        <textarea id="message" name="message" required></textarea>
                    </div>
                    <button type="submit">SEND</button>
                </form>
            </div>              
        </div>
        <section class="pickup-info">
            <div class="pickup-info-overlay">
                <h2>View Pickup and Delivery Options</h2>
                <p>M - F: 9:00am - 6:00pm</p>
                <button><Link to="/contact">More Info</Link></button>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.456324194087!2d-79.39477718450073!3d43.66872927912124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34b0fcaa03b7%3A0xcc2b1658740928a2!2sToronto!5e0!3m2!1sen!2sca!4v1622484070764!5m2!1sen!2sca"
                width="600" height="450" allowFullScreen="" loading="lazy" aria-label="Location map">
            </iframe>
        </section>
    </div>
  );
};

export default pageTransition(Contact);