import React from 'react';
import './Blog.css';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../../assist/index';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const blogPosts = [
  {
      imgSrc: blogImgOne,
      altText: "Wellness and Your Indoor Air Quality",
      title: "Wellness and Your Indoor Air Quality",
      date: "May 17, 2021"
  },
  {
      imgSrc: blogImgTwo,
      altText: "Simple Spring Projects",
      title: "Simple Spring Projects",
      date: "April 05, 2021"

  },
  {
      imgSrc: blogImgThree,
      altText: "Easy as Z to Z",
      title: "Easy as Z to Z",
      date: "February 28, 2021"

  },
  {
      imgSrc: blogImgThree,
      altText: "Easy as Z to Z",
      title: "Easy as Z to Z",
      date: "February 28, 2021"

  },
  {
      imgSrc: blogImgThree,
      altText: "Easy as Z to Z",
      title: "Easy as Z to Z",
      date: "February 28, 2021"

  },
  {
      imgSrc: blogImgThree,
      altText: "Easy as Z to Z",
      title: "Easy as Z to Z",
      date: "February 28, 2021"

  }
  
];

const Blog = () => {

  const maxTitleLength = 35; 
  const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;

  return (
    <div>
      <Helmet>
        <title>SWEET BAE | Blog</title>
        <meta name="description" content="Explore engaging ideas and practical tips on our blog." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="blog-hero">
          <div className="blog-hero-overlay">
            <div className="blog-hero-content">
              <h1>
                  The Potted Post
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className='blog-section'>
            <div className="blog-pottedposts">
                {blogPosts.map(post => (
                    <div key={post.altText} className="pottedpost">
                        <img src={post.imgSrc} alt={post.altText}/>
                        <div className="potted-blog-content">
                            <p>{post.date}</p>
                            <h3>{textLimit(post.title)}</h3>
                            <button><Link to="/individual-blog" reloadDocument>Read More</Link></button>
                        </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageTransition(Blog);
