import React, { useState } from 'react';
import './ProductBundle.css';
import { Helmet } from 'react-helmet';
import { CiStar } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { shopItemOne,
        shopItemTwo,
        shopItemThree,
        shopItemFour,
        shopItemFive } from '../../../assist/index';
import { IoMdClose } from "react-icons/io";


const products = [
    {
        id: 1,
        name: 'Assorted Small Succulents',
        price: 10,
        image: shopItemOne,
        reviews: '5 reviews',
        ratings: 0 
    },
    {
        id: 2,
        name: 'Small Aloe Vera',
        price: 10,
        image: shopItemTwo,
        reviews: '8 reviews',
        ratings: 0
    },
    {
        id: 3,
        name: 'Medium Bonsai',
        price: 25,
        image: shopItemThree,
        reviews: '15 reviews',
        ratings: 0
    }
];

const selectedProducts = [
    { 
        src: shopItemOne, 
        name: 'Assorted Small Succulents'
    },
    {
        src: shopItemTwo, 
        name: 'Small Aloe Vera'
    },
    { 
        src: shopItemThree, 
        name: 'Medium Bonsai'
    },
    { 
        src: shopItemFour, 
        name: 'Orchid'
    },
    { 
        src: shopItemFive, 
        name: 'White Peace'
    }
  ];
const ProductsBundle = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  const handleSelectItem = (product) => {
    const exists = selectedItems.some(item => item.name === product.name);
    if (exists) {
      setSelectedItems(selectedItems.filter(item => item.name !== product.name));
    } else {
      setSelectedItems([...selectedItems, product]);
    }
  };
  

  const handleRemoveItem = (name) => {
    setSelectedItems(selectedItems.filter(item => item.name !== name));
  };

  const handlePrevClick = () => {
    console.log("Prev clicked");
    if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
    } else {
        setCurrentIndex(selectedProducts.length - 1);
    }
};

const handleNextClick = () => {
    console.log("Next clicked");
    if (currentIndex < selectedProducts.length - 1) {
        setCurrentIndex(currentIndex + 1);
    } else {
        setCurrentIndex(0);
    }
};
  
  return (
    <>
        <Helmet>
            <title>SWEET BAE | Create Your Bundle</title>
            <meta name="description" content="Customize your purchase with our 'Create Your Own Bundle' feature. Select from a variety of products to build your perfect set and save 20%!" />
        </Helmet>
        <p className="discount">30% off spring sale</p>
        <div className="wrapper">
            <div className="product-bundle-sidebar">
                <aside class="product-bundle-filter-sidebar">
                    <div className="product-bundle-details">
                    <h1>Create Your Own Bundle</h1>
                    <h2>$125</h2>
                    <h3>Save 20% when you create your own bundle!</h3>
                    <p>Select items from the thumbnail photos located on the bottom right to build your bundle.</p>
                    <div className="side-ratings">
                            <CiStar className="ratings-star"/>
                            <CiStar className="ratings-star"/>
                            <CiStar className="ratings-star"/>
                            <CiStar className="ratings-star"/>
                            <CiStar className="ratings-star"/>
                            # reviews
                        </div>
                        <div className="add-to-cart">
                        <button><Link to="/" reloadDocument>ADD TO CART</Link></button>
                    </div>
                    <div className="buy-now">
                        <button><Link to="/" reloadDocument>BUY NOW</Link></button>
                    </div>
                    </div>
                </aside>
            </div>
            <div className="main">
            <div>
            <div className="product-image">
                <div className="selected-items-background">
                    {selectedItems.map((item, index) => (
                        <div key={index} className="selected-items" style={{ position: 'relative' }}>
                            <img src={item.src} alt={item.name} />
                            <div className="close-icon" onClick={() => handleRemoveItem(item.name)}>
                                <IoMdClose size={24} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="image-other">
                    {selectedProducts.map(product => (
                        <img
                            key={product.name}
                            src={product.src}
                            alt={product.name}
                            onClick={() => handleSelectItem(product)}
                        />
                    ))}
                </div>
                </div>
                <div>
                    <div className="product-header">
                        <h2>Similar Product</h2>
                    </div> 
                    <div className="product-shop-grid">
                        {products.map(plant => (
                        <div className="product-plant-item" key={plant.id}>
                            <img src={plant.image} alt={plant.name} />
                                <div className="description">
                                    <div className="info">
                                        <span className="name"><h4>{plant.name}</h4></span>
                                    </div>
                                    <span className="price">${plant.price}</span>
                                </div>
                            <div className="meta">
                                <span className="ratings">
                                    <CiStar className="ratings-star"/>
                                    <CiStar className="ratings-star"/>
                                    <CiStar className="ratings-star"/>
                                    <CiStar className="ratings-star"/>
                                    <CiStar className="ratings-star"/>
                                </span>
                            <span className="reviews">{plant.reviews}</span>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            </div>
        </div> 
    </>
  )
}

export default ProductsBundle;