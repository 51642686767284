import React from 'react';
import './Body.css';
import HeroSection from './Herosection';
import Shopsection from './Shopsection';
import PromoSection from './Promosection';
import ShopBysection from './shopBysection';
import BlogSection from './BlogSection';
import Caretips from './Caretips';
import PickupInfo from './Pickupinfo';
import { Helmet } from 'react-helmet';
import pageTransition from '../../variants/pageTransition';


const Body = () => {
    return (
        <>
            <Helmet>
                <title>SWEET BAE</title>
                <meta name="description" content="Discover the charm of SWEET BAE with our range of products and unique boutique items. Explore our story and shop with us today." />
            </Helmet>
            <HeroSection />
            <div className="container">
                <Shopsection />
                <ShopBysection />
            </div>
            <PromoSection />
            <div className="container">
                <BlogSection />
            </div>
            <Caretips />
            <PickupInfo />
        </>
    );
}

export default pageTransition(Body);