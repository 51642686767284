import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MdLabelImportant } from "react-icons/md";
import {
  seasonalPlaintingSlideOne,
  seasonalPlaintingSlideTwo
} from '../../../assist/index';
import './Services.css';
import pageTransition from '../../../variants/pageTransition';

const seasonalPlanting = {
  overview: "Embrace each season with our Seasonal Planting services. We specialize in selecting and planting annuals, perennials, and seasonal blooms that thrive in your local climate. Our expert team provides consultations and planting services to ensure your garden stays vibrant and colorful all year round. Perfect for residential gardens and commercial landscapes looking for a seasonal refresh.",
  capabilities: [
    "Expert selection of plants that are best suited to the local climate and soil conditions for each season.",
    "Spring and fall bulb planting for a continuous bloom cycle.",
    "Colorful annuals and robust perennials that enhance your garden’s aesthetic.",
    "Seasonal updates to plant beds and containers to keep your space dynamic and engaging.",
    "Maintenance tips and follow-up care to ensure plant health throughout the season."
  ],
  recognition: "Our Seasonal Planting services have been highlighted in 'Seasonal Gardening Today' and received the Community Beautification Award for enhancing public and private spaces.",
  detailedServices: [
    {
      title: "Spring Renewal Plantings",
      description: "Brighten up your garden with vibrant flowers and foliage selected to peak throughout the spring months."
    },
    {
      title: "Autumn Color Boosts",
      description: "Transform your landscape with autumn hues using plants that flourish in cooler temperatures and provide late-season blooms."
    },
    {
      title: "Holiday & Festive Displays",
      description: "Specialty planting services for holidays and festive occasions, creating eye-catching displays that celebrate the season."
    }
  ],
};

const SeasonalPlanting = () => {

  return (
    <div>
      <Helmet>
        <title>CICELY | Seasonal Planting Services</title>
        <meta name="description" content="Explore our seasonal planting services designed to keep your garden vibrant and festive throughout the year. Learn about our tailored plant selection, seasonal maintenance, and eco-friendly practices." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="service-hero-image">
            <div className="service-hero">
                <h1>
                    Seasonal Planting
                </h1>
            </div>
        </div>
      </div>
      <div className="container">
        <div className="service-details-layout">
            <div className="service-text">
            <p>{seasonalPlanting.overview}</p>
            <div>
                <h2>Our Capabilities</h2>
                <ul>
                {seasonalPlanting.capabilities.map((capability, index) => (
                    <li key={index}>{capability}</li>
                ))}
                </ul>
            </div>
            <div>
                <h2>Recognition</h2>
                <p>{seasonalPlanting.recognition}</p>
            </div>
            <div>
                <h2>What We Offer</h2>
                {seasonalPlanting.detailedServices.map((detail, index) => (
                <article key={index}>
                    <h3><MdLabelImportant className='label'/>{detail.title}</h3>
                    <p>{detail.description}</p>
                </article>
                ))}
            </div>
            </div>
            <div className="service-images">
            <img src={seasonalPlaintingSlideOne} alt="Holiday & Festive Displays" />
            <img src={seasonalPlaintingSlideTwo} alt="Autumn Color Boosts" />
            </div>
        </div>
      </div>
      <div>
          <div className="service-bottom-hero">
              <div className="service-bottom-hero-content">
              <h2>
                  Brighten Your Seasons <br/>
                  with Vibrant Plantings!
              </h2>
                  <h3>Contact us today!</h3>
                  <button><Link to="/contact" reloadDocument>Talk to us</Link></button>
              </div>
          </div>
      </div>
    </div>
  );
}

export default pageTransition(SeasonalPlanting);