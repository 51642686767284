import React from 'react';
import { Link } from 'react-router-dom';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../assist/index';

const blogPosts = [
    {
        imgSrc: blogImgOne,
        altText: "Wellness and Your Indoor Air Quality",
        title: "Wellness and Your Indoor Air Quality",
        date: "May 17, 2021"
    },
    {
        imgSrc: blogImgTwo,
        altText: "Simple Spring Projects",
        title: "Simple Spring Projects",
        date: "April 05, 2021"

    },
    {
        imgSrc: blogImgThree,
        altText: "Easy as Z to Z",
        title: "Easy as Z to Z",
        date: "February 28, 2021"

    }
];

const maxTitleLength = 25; 
const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
    

const BlogSection = () => {
  return (
    <div>
        <section className='blog-section'>
        <h1>From The Potted Post</h1>
            <button><Link to="/blog" reloadDocument>View All</Link></button>
            <div className="blog-posts">
                {blogPosts.map(post => (
                    <div key={post.altText} className="post">
                        <img src={post.imgSrc} alt={post.altText}/>
                        <div className="blog-content">
                            <p>{post.date}</p>
                            <h3 title={post.title}>{textLimit(post.title)}</h3>
                            <button><Link to="/individual-blog" reloadDocument>Read More</Link></button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </div>
  )
}

export default BlogSection