import React, { useState } from 'react';
import './PlantDirectory.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import {  shopItemOne,  
        shopItemTwo,
        shopItemThree,
        shopItemFour,
        shopItemFive,
        shopItemSix,
        shopItemSeven,
        shopItemEight,
        shopItemNine } from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';

const plantItems = [
  {
    label: "Sold by Cicely",
    img: shopItemOne,
    category: "Plant",
    name: "Assorted Small Succulents",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: shopItemTwo,
    category: "Plant",
    name: "Small Aloe Vera",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: shopItemThree,
    category: "Plant",
    name: "Medium Bonsai",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: shopItemFour,
    category: "Plant",
    name: "Medium Orchid",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Cicely",
    img: shopItemFive,
    category: "Plant",
    name: "Large White Peace Lily",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemSix,
    category: "Houseplants",
    name: "Large Monstera (Swiss Cheese) Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemSeven,
    category: "Houseplants",
    name: "Large Snake Plant",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemEight,
    category: "Plant",
    name: "Large Cactus",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemNine,
    category: "Office Plants",
    name: "Office Bundle",
    ratings: "★★★★★",
    reviews: "#reviews",
    path: "/individual-plant"
  }
];

const PlantDirectory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [checkedState, setCheckedState] = useState({
    "broadleaf": false,
    "evergreens": false,
    "fruit": false,
    "perennials": false,
    "roses": false,
    "shrubs": false,
    "trees": false,
  });
  const [selectedLabels, setSelectedLabels] = useState({
    "broadleaf": false,
    "evergreens": false,
    "fruit": false,
    "perennials": false,
    "roses": false,
    "shrubs": false,
    "trees": false,
  });
  

  const handleClick = (dropdownName) => {
    setActiveDropdown(dropdownName === activeDropdown ? null : dropdownName);
  }

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    const sidebar = document.querySelector('.shop-sidebar');
    sidebar.classList.add('closing');
    setTimeout(() => {
      sidebar.classList.remove('closing');
      setIsSidebarOpen(false);
    }, 300); 
  };


  // Function to handle label click and toggle yellow highlight
  const handleLabelClick = (label) => {
    setSelectedLabels((prev) => ({
      ...prev,
      [label]: !prev[label], // Toggle the selection state
    }));
  };

  // Modified clearFilters function to remove all highlights
  const clearFilters = (category) => {
    setCheckedState((prev) => ({
      ...prev,
      [category]: [] // Clear all checked states
    }));

    // Clear all label highlights when filters are cleared
    setSelectedLabels({
      "broadleaf": false,
      "evergreens": false,
      "fruit": false,
      "perennials": false,
      "roses": false,
      "shrubs": false,
      "trees": false,
    });
  };

  return (
    <div>
      <Helmet>
        <title>SWEET BAE | Plant Directory</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="wrapper">
        <div className={`shop-sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={closeSidebar}><IoMdClose/></button>
          <div className="shop-filter-sidebar">
          <div className="shop-filter-section">
            <h4 onClick={() => handleClick("plant-type")}>
              Plant Type <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'plant-type' ? 'rotate' : ''}`} />
            </h4>
            {activeDropdown === 'plant-type' && (
              <div className="shop-filter-section-submenu">
                <div>
                  <label
                    htmlFor="broadleaf"
                    onClick={() => handleLabelClick("broadleaf")}
                    style={{ color: selectedLabels["broadleaf"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Broadleaf Evergreen
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="evergreens"
                    onClick={() => handleLabelClick("evergreens")}
                    style={{ color: selectedLabels["evergreens"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Evergreens
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="fruit"
                    onClick={() => handleLabelClick("fruit")}
                    style={{ color: selectedLabels["fruit"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Fruit
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="perennials"
                    onClick={() => handleLabelClick("perennials")}
                    style={{ color: selectedLabels["perennials"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Perennials
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="roses"
                    onClick={() => handleLabelClick("roses")}
                    style={{ color: selectedLabels["roses"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Roses
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="shrubs"
                    onClick={() => handleLabelClick("shrubs")}
                    style={{ color: selectedLabels["shrubs"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Shrubs
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="trees"
                    onClick={() => handleLabelClick("trees")}
                    style={{ color: selectedLabels["trees"] ? "var(--accent-color)" : "var(--primary-color)" }}
                  >
                    Trees
                  </label>
                </div>
                <button onClick={() => clearFilters("plant-type")} className="clear-all-button">Clear All</button>
              </div>
            )}
          </div>
  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("flower-colour")}>
      Flower Colour <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'flower-colour' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'flower-colour' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="colourful-fruit"
            onClick={() => handleLabelClick("colourful-fruit")}
            style={{ color: selectedLabels["colourful-fruit"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Colourful Fruit
          </label>
        </div>
        <div>
          <label
            htmlFor="all-season-foliage"
            onClick={() => handleLabelClick("all-season-foliage")}
            style={{ color: selectedLabels["all-season-foliage"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            All Season Foliage
          </label>
        </div>
        <div>
          <label
            htmlFor="colourful-twigs"
            onClick={() => handleLabelClick("colourful-twigs")}
            style={{ color: selectedLabels["colourful-twigs"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Colourful Twigs
          </label>
        </div>
        <div>
          <label
            htmlFor="foliage-all-season"
            onClick={() => handleLabelClick("foliage-all-season")}
            style={{ color: selectedLabels["foliage-all-season"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Foliage all Season
          </label>
        </div>
        <div>
          <label
            htmlFor="blue"
            onClick={() => handleLabelClick("blue")}
            style={{ color: selectedLabels["blue"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Blue
          </label>
        </div>
        <div>
          <label
            htmlFor="golden-foliage"
            onClick={() => handleLabelClick("golden-foliage")}
            style={{ color: selectedLabels["golden-foliage"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Golden Foliage
          </label>
        </div>
        <div>
          <label
            htmlFor="fall-foliage"
            onClick={() => handleLabelClick("fall-foliage")}
            style={{ color: selectedLabels["fall-foliage"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Fall Foliage
          </label>
        </div>
        <button onClick={() => clearFilters("flower-colour")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("light-requirements")}>
      Light Requirements <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'light-requirements' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'light-requirements' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="full-sun"
            onClick={() => handleLabelClick("full-sun")}
            style={{ color: selectedLabels["full-sun"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Full Sun
          </label>
        </div>
        <div>
          <label
            htmlFor="partial-shade"
            onClick={() => handleLabelClick("partial-shade")}
            style={{ color: selectedLabels["partial-shade"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Partial Shade
          </label>
        </div>
        <div>
          <label
            htmlFor="full-shade"
            onClick={() => handleLabelClick("full-shade")}
            style={{ color: selectedLabels["full-shade"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Full Shade
          </label>
        </div>
        <button onClick={() => clearFilters("light-requirements")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("shape")}>
      Shape <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'shape' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'shape' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="semi-dwarf"
            onClick={() => handleLabelClick("semi-dwarf")}
            style={{ color: selectedLabels["semi-dwarf"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Semi Dwarf
          </label>
        </div>
        <div>
          <label
            htmlFor="upright"
            onClick={() => handleLabelClick("upright")}
            style={{ color: selectedLabels["upright"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Upright
          </label>
        </div>
        <div>
          <label
            htmlFor="bushy"
            onClick={() => handleLabelClick("bushy")}
            style={{ color: selectedLabels["bushy"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Bushy
          </label>
        </div>
        <div>
          <label
            htmlFor="spreading"
            onClick={() => handleLabelClick("spreading")}
            style={{ color: selectedLabels["spreading"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Spreading
          </label>
        </div>
        <div>
          <label
            htmlFor="globe"
            onClick={() => handleLabelClick("globe")}
            style={{ color: selectedLabels["globe"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Globe
          </label>
        </div>
        <div>
          <label
            htmlFor="fast-growing"
            onClick={() => handleLabelClick("fast-growing")}
            style={{ color: selectedLabels["fast-growing"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Fast growing
          </label>
        </div>
        <div>
          <label
            htmlFor="mound"
            onClick={() => handleLabelClick("mound")}
            style={{ color: selectedLabels["mound"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Mound
          </label>
        </div>
        <div>
          <label
            htmlFor="dwarf"
            onClick={() => handleLabelClick("dwarf")}
            style={{ color: selectedLabels["dwarf"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Dwarf
          </label>
        </div>
        <button onClick={() => clearFilters("shape")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("plant-use")}>
      Plant Use <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'plant-use' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'plant-use' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="edible"
            onClick={() => handleLabelClick("edible")}
            style={{ color: selectedLabels["edible"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Edible
          </label>
        </div>
        <div>
          <label
            htmlFor="cascading"
            onClick={() => handleLabelClick("cascading")}
            style={{ color: selectedLabels["cascading"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Cascading
          </label>
        </div>
        <div>
          <label
            htmlFor="low-growing"
            onClick={() => handleLabelClick("low-growing")}
            style={{ color: selectedLabels["low-growing"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Low Growing
          </label>
        </div>
        <div>
          <label
            htmlFor="vigorous"
            onClick={() => handleLabelClick("vigorous")}
            style={{ color: selectedLabels["vigorous"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Vigorous
          </label>
        </div>
        <div>
          <label
            htmlFor="hardy"
            onClick={() => handleLabelClick("hardy")}
            style={{ color: selectedLabels["hardy"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Hardy
          </label>
        </div>
        <div>
          <label
            htmlFor="ground-cover"
            onClick={() => handleLabelClick("ground-cover")}
            style={{ color: selectedLabels["ground-cover"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Ground Cover
          </label>
        </div>
        <div>
          <label
            htmlFor="rock-garden"
            onClick={() => handleLabelClick("rock-garden")}
            style={{ color: selectedLabels["rock-garden"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Rock Garden
          </label>
        </div>
        <div>
          <label
            htmlFor="plant-bed"
            onClick={() => handleLabelClick("plant-bed")}
            style={{ color: selectedLabels["plant-bed"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Plant Bed
          </label>
        </div>
        <button onClick={() => clearFilters("plant-use")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("bloom-time")}>
      Bloom Time <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'bloom-time' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'bloom-time' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="spring-blooms"
            onClick={() => handleLabelClick("spring-blooms")}
            style={{ color: selectedLabels["spring-blooms"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Spring Blooms
          </label>
        </div>
        <div>
          <label
            htmlFor="summer-blooms"
            onClick={() => handleLabelClick("summer-blooms")}
            style={{ color: selectedLabels["summer-blooms"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Summer Blooms
          </label>
        </div>
        <div>
          <label
            htmlFor="fall-blooms"
            onClick={() => handleLabelClick("fall-blooms")}
            style={{ color: selectedLabels["fall-blooms"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Fall Blooms
          </label>
        </div>
        <div>
          <label
            htmlFor="winter-blooms"
            onClick={() => handleLabelClick("winter-blooms")}
            style={{ color: selectedLabels["winter-blooms"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Winter Blooms
          </label>
        </div>
        <div>
          <label
            htmlFor="summer-fall-blooms"
            onClick={() => handleLabelClick("summer-fall-blooms")}
            style={{ color: selectedLabels["summer-fall-blooms"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Summer & Fall Blooms
          </label>
        </div>
        <button onClick={() => clearFilters("bloom-time")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("indoor-outdoor")}>
      Indoor/Outdoor <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'indoor-outdoor' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'indoor-outdoor' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="outdoor"
            onClick={() => handleLabelClick("outdoor")}
            style={{ color: selectedLabels["outdoor"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Outdoor
          </label>
        </div>
        <div>
          <label
            htmlFor="indoor"
            onClick={() => handleLabelClick("indoor")}
            style={{ color: selectedLabels["indoor"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Indoor
          </label>
        </div>
        <button onClick={() => clearFilters("indoor-outdoor")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>

  <div className="shop-filter-section">
    <h4 onClick={() => handleClick("sold-by")}>
      Sold by Cicely <IoIosArrowDown className={`arrow-icon ${activeDropdown === 'sold-by' ? 'rotate' : ''}`} />
    </h4>
    {activeDropdown === 'sold-by' && (
      <div className="shop-filter-section-submenu">
        <div>
          <label
            htmlFor="sold-by-sheridan"
            onClick={() => handleLabelClick("sold-by-sheridan")}
            style={{ color: selectedLabels["sold-by-sheridan"] ? "var(--accent-color)" : "var(--primary-color)" }}
          >
            Sold by Cicely
          </label>
        </div>
        <button onClick={() => clearFilters("sold-by")} className="clear-all-button">Clear All</button>
      </div>
    )}
  </div>
          </div>
        </div>
        <div className="main">
          <div className="shop-hero-image">
              <div className="shop-hero">
                <h1>Plant Directory</h1>
              </div>
          </div> 
          <button className="filter-button" onClick={toggleSidebar}>Filter</button>
          <div className="container">
            <div className="gift">
              <div className="gift-header">
                <div className="gift-header-content">
                  <p className="plant-intro">Find the perfect plants for your garden, big small. Search by plant name or specify your plant requirements and our handy plant finder will find the perfect match for you!</p>
                </div>
              </div>
            </div>
            <div className="shop-grid">
              {plantItems.map(plant => (
                <div className="plant-item" key={plant.id}>
                  {plant.label && <div className="label">{plant.label}</div>}
                  <img src={plant.img} alt={plant.name} />
                  <div className="description">
                    <div>
                      <span className="category"><h3>{plant.category}</h3></span>
                      <Link to={plant.path} reloadDocument>
                        <span className="name"><h4>{plant.name}</h4></span>
                      </Link>
                    </div>
                  </div>
                  <div className="meta">
                    <span className="ratings">{plant.ratings}</span>
                    <span className="reviews">{plant.reviews}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        </div>
        <div className="shop-bottom-hero-image">
          <div className="shop-bottom-hero">
            <h2>Not sure where to start? We do.</h2>
            <h3>We're here to help.</h3>
            <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
          </div>
        </div>
    </div>
  )
}

export default pageTransition(PlantDirectory);
