import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layouts/navbar/Navbar';
import Body from './components/body/Body';
import Footer from './components/layouts/footer/Footer';
import Contact from './components/pages/contact/Contact';
import Shop from './components/pages/shop/Shop';
import Blog from './components/pages/blog/Blog';
/*
import ProductBundle from './components/productBundle/ProductBundle';
*/
import About from './components/pages/about/About';
import Legal from './components/pages/legal/Legal';
import Privacy from './components/pages/privacy/Privacy';
import AllServices from './components/pages/allServices/AllServices';
import AllCaseStudies from './components/pages/allCaseStudies/AllCaseStudies';
import SeasonalPlanting from './components/pages/services/SeasonalPlanting';
import PlantClass from './components/pages/services/PlantClass';
import PlantDesign from './components/pages/services/PlantDesign';
import PlantHealth from './components/pages/services/PlantHealth';
import PlantInstall from './components/pages/services/PlantInstall';
import PlantMaint from './components/pages/services/PlantMaint';
import CustomPlantDesign from './components/pages/caseStudies/CustomPlantDesign';
import EducationPlantClass from './components/pages/caseStudies/EducationPlantClass';
import OfficePlantInstall from './components/pages/caseStudies/OfficePlantInstall';
import ParkSeasonPlant from './components/pages/caseStudies/ParkSeasonPlant';
import ResGardenPlantHealth from './components/pages/caseStudies/ResGardenPlantHealth';
import ResortPlantMaint from './components/pages/caseStudies/ResortPlantMaint';
import Products from './components/pages/product/Products';
import ProductsBundle from './components/pages/productBundle/ProductsBundle';
import PlantDirectory from './components/pages/plantDirectory/PlantDirectory';
import IndividualPlant from './components/pages/individualPlant/IndividualPlant';
import IndividualBlog from './components/pages/individualBlog/IndividualBlog';


function App() {
  return (
    <Router>
      <Navbar />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/plant-directory" element={<PlantDirectory />} />
          <Route path="/individual-plant" element={<IndividualPlant />} />
          <Route path="/individual-blog" element={<IndividualBlog />} />
          {/* 
          <Route path="/shop" element={<Shop />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product-bundle" element={<ProductsBundle />} />
          */}
          <Route path="/all-services" element={<AllServices />} />
          <Route path="/all-case-studies" element={<AllCaseStudies />} />
          <Route path="/seasonal-planting" element={<SeasonalPlanting />} />
          <Route path="/plant-classes" element={<PlantClass />} />
          <Route path="/plant-design" element={<PlantDesign />} />
          <Route path="/plant-health" element={<PlantHealth />} />
          <Route path="/plant-install" element={<PlantInstall />} />
          <Route path="/plant-maint" element={<PlantMaint />} />
          <Route path="/custome-plant-design" element={<CustomPlantDesign />} />
          <Route path="/education-plant-class" element={<EducationPlantClass />} />
          <Route path="/office-plant-install" element={<OfficePlantInstall />} />
          <Route path="/park-season-plant" element={<ParkSeasonPlant />} />
          <Route path="/resident-garden-plant-health" element={<ResGardenPlantHealth />} />
          <Route path="/resort-plant-maintenance" element={<ResortPlantMaint />} />
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
