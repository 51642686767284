import React from 'react';
import { Link } from 'react-router-dom';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../assist/index';

const blogPosts = [
    {
        imgSrc: blogImgOne,
        altText: "Wellness and Your Indoor Air Quality",
        title: "Wellness and Your Indoor Air Quality",
        date: "May 17, 2021"
    },
    {
        imgSrc: blogImgTwo,
        altText: "Simple Spring Projects",
        title: "Simple Spring Projects",
        date: "April 05, 2021"

    },
    {
        imgSrc: blogImgThree,
        altText: "Easy as Z to Z",
        title: "Easy as Z to Z",
        date: "February 28, 2021"

    }
];

const PromoSection = () => {
    return (
        <section className="promo-section">
            <div className="promo-content">
                <h2>Our sweet bae promise to you</h2>
                <h3>Quality you can trust.</h3>
                <button><Link to="/plant-directory" reloadDocument>Read More</Link></button>
            </div>
        </section>
    );
};

export default PromoSection;
