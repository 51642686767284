import React from 'react';
import { Link } from 'react-router-dom';
import './Body.css';

const Caretips = () => {
  return (
    <>
        <section className="care-tips">
          <div className="care-content">
            <h2>Easy Care Tips for Your Plants</h2>
            <h3>We're here to help.</h3>
            <button><Link to="/blog" reloadDocument>Read Tips</Link></button>
          </div>
        </section>
    </>
  )
}

export default Caretips