import React from 'react';
import { Link } from 'react-router-dom';
import { plantInstallSlideOne,
        plantClassSlideOne,
        plantHealthOne,
        plantDesignOne
} from '../../assist/index';
import './Body.css'

const shopByItems = [
    {
        imgSrc: plantClassSlideOne,
        altText: "Plant Classes",
        linkTo: "/plant-classes",
        buttonText: "Plant Classes"
    },
    {
        imgSrc: plantDesignOne,
        altText: "Plant Design",
        linkTo: "/plant-design",
        buttonText: "Plant Design"
    },
    {
        imgSrc: plantHealthOne,
        altText: "Plant Health",
        linkTo: "/plant-health", 
        buttonText: "Plant Health"
    },
    {
        imgSrc: plantInstallSlideOne,
        altText: "Plant Installation",
        linkTo: "/plant-install",
        buttonText: "Plant Installation"
    }
];

const shopBysection = () => {
  return (
    <>
        <section className="services">
            <h1>Service</h1>
            <div className="service-items">
                {shopByItems.map(item => (
                    <div key={item.altText} className="item">
                        <img src={item.imgSrc} alt={item.altText} />
                        <div className="overlay">
                            <button>
                                <Link to={item.linkTo} reloadDocument>{item.buttonText}</Link>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </>
  )
}

export default shopBysection