import React from 'react';
import { Link } from 'react-router-dom';
import './Body.css';
import {
    shopImgOne,
    shopImgTwo
} from '../../assist/index'

const shopItems = [
    {
        imgSrc: shopImgOne,
        altText: "Monstera leaves, a perfect pair for modern interiors",
        title: "A Perfect Pair",
        description: "We've got the perfect plant for your perfect pot!",
        linkTo: "/plant-directory",
        linkText: "Shop"
    },
    {
        imgSrc: shopImgTwo,
        altText: "Assorted pots suitable for every personality",
        title: "Thoughtful Gifting",
        description: "Event and corporate gifts everyone will love.",
        linkTo: "/plant-directory",
        linkText: "Event Gifts"
    }
];

const ShopSection = () => {
    return (
        <>
            <section>
                <div className="hero-grid">
                    {shopItems.map(item => (
                        <div key={item.altText} className="hero-grid-item">
                            <img src={item.imgSrc} alt={item.altText} loading="lazy"/>
                            <div className="hero-item-overlay">
                                <h1>{item.title}</h1>
                                <p>{item.description}</p>
                                <button>
                                    <Link to={item.linkTo} aria-label={`Shop the entire collection for ${item.title}`} reloadDocument>{item.linkText}</Link>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default ShopSection;